<template>
    <div>
        <div class="content-list-master" v-if="!loading" :class="{'content-list-unlocked': !getLockContentTree, 'sidebar-open-padding-left': getSidebarOpenState}">
            <div class="content-list-root-node">
                <h1>1 - {{ getClientData.slug }}</h1>
            </div>
            <cardss />
                <draggable
                class="dragArea"
                tag="div"
                :list="categoryNodes"
                :group="{ name: 'g1' }"
                itemKey="id"
                @end="handleEnd"
                v-bind="dragOptions"
                :disabled="getLockListCategories"
            >
                <template #item="{element, index}">
                    <ContentList @click.prevent="openCategoryNode(element.id, element.isImported)" class="content-list-wrap" level="1" :isImported="element.isImported ? true : false" :id="element.id" :childNodes="element" :key="index" />
                </template>
            </draggable>

            <div class="content-list__commands">

                <button  v-if="getLockContentTree === false" class="btn-save btn-save--outline" @click.prevent="addCategoryNodeToList">
                    <i class="fa-solid fa-plus"></i>
                    Add Category
                </button>

                <button v-if="getLockContentTree === false" class="btn-save" @click.prevent="saveContentList">
                    <i class="fa-solid fa-floppy-disk"></i>
                    Save Changes
                </button>

                <button v-if="getLockContentTree" class="btn-primary btn-primary--outline content-list-lock-icon content-list-lock-btn" @click.prevent="this.$store.commit('client/setLockContentTree', false)">
                    <i class="fa-regular fa-lock"></i>
                    Unlock
                </button>

                <button v-if="getLockContentTree === false" class="btn-primary btn-primary--outline content-list-lock-icon content-list-lock-btn" @click.prevent="this.$store.commit('client/setLockContentTree', true)">
                    <i class="fa-regular fa-lock-open"></i>
                    Lock
                </button>

                <button class="btn-primary btn-primary--outline content-list-lock-icon content-list-filter" @click.prevent="this.$store.commit('client/setMutliSelectNodeCategories', {prop: 'modalIsOpen', val: true})">
                    <i class="fa-regular fa-filter"></i>
                    Filter
                </button>

                <button v-if="showListView()" @click.prevent="this.setListView()" class="btn-primary btn-primary--outline btn-list-view">
                    <i class="fa-regular fa-share-nodes"></i>
                    Content Tree
                </button>


            </div>

        </div>

        <div v-if="loading">
            <h1>Retrieving Data</h1>
        </div>

    </div>

</template>

<script>

import ContentList from './ContentList.vue';
import {mapGetters} from "vuex";
import draggable from "vuedraggable";
import Node from '../../services/contentNodes';

let main = null
let sidebarWrapper = null
let nodeForm = null
let nodeOverview = null

export default {
    components: {
        ContentList,
        draggable,

    },
    data() {
        return {
            categoryNodes: [],
            loading: true,
            userSaved: false,
            hasMovedNode: false,
            changedNodes: [],
            hasContent: false,
            hideLoader: false,
            isSaving: false,
        };
    },
    async mounted(){
        try{
            main = document.getElementById("main")
            sidebarWrapper = document.getElementById("sidebarWrapper")
            nodeForm = document.getElementById("add-node-form")
            nodeOverview = document.getElementById("node-overview")
            if(this.getClientContentTree && this.getClientContentTree.nodes && this.getClientContentTree.nodes.length > 0){
                main = document.getElementById("main")
                nodeOverview = document.getElementById("node-overview")
                nodeForm = document.getElementById("add-node-form")
                await this.$store.dispatch('client/getImportedContentTree');
                this.$store.commit('client/setNoDragContentListItems');
                await this.ifNodeOpenOverview();
                this.loading = false;
                this.$store.commit('client/setHidePageLoader', true);
            }

            if(this.getContentStrategy.isImporting){
                this.$store.commit('client/setLockContentTree', false);
            }
        }catch(error){
            console.log('mounted contentLastMaster catch error', error);
        }
    },
    watch:{
        'getImportedContentTree':{
            handler() {
                this.addImportedNodes();
            },
            deep: true
        },
        'getClientContentTree.nodes':{
            async handler() {
                try{
                    main = document.getElementById("main")
                    nodeOverview = document.getElementById("node-overview")
                    nodeForm = document.getElementById("add-node-form")
                    await this.$store.dispatch('client/getImportedContentTree');
                    this.$store.commit('client/setNoDragContentListItems');
                    await this.nodesInit();
                    if(this.isSaving === false){
                        this.$store.commit('client/setHidePageLoader', true);
                    }
                }catch(error){
                    console.log('contentListMaster catch error getting nodes watcher', )
                }
            },
            deep: true
        },
        'getMutliSelectNodeCategories.filteredCategoryNodes':{
            handler(nodes) {
                //any changes remove or add the cataegories
                console.log('step1-filter:', nodes);
                if(nodes){
                    let currentNodes = document.querySelectorAll('.content-list-master .content-list-wrap[level="1"]');
                    currentNodes.forEach(categoryNode => {
                        if(nodes.find(node => node.code === categoryNode.id)){
                            categoryNode.style.display = 'block';
                        }else{
                            categoryNode.style.display = 'none';
                        }
                    })
                }
            },
            deep: true
        },
        hideLoader(VAL){
            console.log('hide loader watcher:', VAL);
        },
        categoryNodes(){
            //This only watches the outer category lists (greyed out) changes not the nodes inside them
            if(this.hasContent === false){
                setTimeout(() => {
                    this.hideLoader = true;
                    this.hasContent = true;
			    }, 2000)
            }
        },
        'getCurrentNode':{
            async handler() {
                setTimeout(() => {
                    if(this.isSaving === false){
                        this.$store.commit('client/setHidePageLoader', true);
                    }
			    }, 2000)
            },
            deep: true
        },
        async getChangedNode(id){
            try{
                console.log('getChangedNode before:', id);
                await new Promise(resolve => setTimeout(resolve, 500));
                console.log('getChangedNode after:', [id, this.categoryNodes]);
                if(!id){ return; }
                const changedEl = document.querySelector(`.content-list-master .item-group[id='${id}']`);
                this.userSaved = false;
                this.hasMovedNode = true;
                if(!changedEl){ console.log('changed node element could not be found'); return; }

                if(changedEl.parentElement){
                    let doNotUpdate = false;
                    const categoryIsParent = changedEl.querySelector('[level="2"]') ? true : false;
                    console.log('categoryIsParent', categoryIsParent);
                    const parentEl = categoryIsParent ? changedEl.closest('[level="1"]') : changedEl.parentElement.parentElement.parentElement.parentElement;
                    console.log('parentEl', parentEl);
                    const parentID = parentEl.id;
                    console.log('parentID', parentID);
                    const siblingBeforeID = changedEl.previousElementSibling ? changedEl.previousElementSibling.id : '';
                    const siblingNextID = changedEl.nextElementSibling ? changedEl.nextElementSibling.id : '';
                    const status1 = await this.checkIfSibling(siblingBeforeID, siblingNextID);
                    const status2 = this.checkIfNodeMovedToImportedNodesList(id, parentID, siblingBeforeID, siblingNextID);
                    const status3 = categoryIsParent ? false : this.checkIfXdeep(changedEl, parentEl);
                    doNotUpdate = status1 || status2 || status3 ? true : false;
                    const data = {
                        id: id,
                        siblingBeforeID: siblingBeforeID, 
                        siblingNextID: siblingNextID, 
                        parentID: parentID,
                        categoryIsParent: categoryIsParent,
                        doNotUpdate: doNotUpdate
                    }
                    await this.setChangeMovementOfNode(data);
                }else{
                    console.log('user has moved a category block, not a node');
                }
                this.$store.commit('client/setChangedNode', '');
                this.$store.commit('client/clearSelectedNode');
            }catch(error){
                console.log('getChangedNode catch error', error);
            }
        },
    },
    async created() {
        try{
            if(this.getClientContentTree && this.getClientContentTree.nodes && this.getClientContentTree.nodes.length > 0){
                await this.nodesInit();
                main = document.getElementById("main")
                nodeOverview = document.getElementById("node-overview")
                nodeForm = document.getElementById("add-node-form")
            }
        }catch(error){
            console.log('contentListMaster created catch error', error);
        }
    },
    methods:{
        showListView(){
            if((this.userSaved && this.hasMovedNode) || (!this.userSaved && !this.hasMovedNode)){
                return true;
            }else{
                return false;
            }
        },
        checkIfXdeep(changedEl, parentEl){
            const childrenCount = changedEl.querySelectorAll('.item').length - 1;
            if(parseInt(parentEl.querySelector('.item').getAttribute('level')) + childrenCount >= 8){
                alert('You cannot nest anymore than 8 nodes');
                return true;
            }
        },
        openAddNode() {
			if (nodeForm.classList.contains("form-closed")  && this.getContentStrategy.isImporting === false) {
				main.classList.remove("node-form-closed")
				main.classList.add("node-form-open")
				nodeForm.classList.remove("form-closed")
				nodeForm.classList.add("form-open")
			}
			this.closeNodeOverview()
		},
		closeSidebar() {
			this.$store.dispatch("views/closeSidebar")
			if (!this.getSidebarOpenState) {
				sidebarWrapper.classList.remove("open")
				sidebarWrapper.classList.add("closed")
			}
		},
        setListView(){
            this.userSaved = false;
            this.hasMovedNode = false;
            this.$store.commit('client/setLockContentTree', false)
            this.$store.commit('client/setListView', false);
        },
        isNewCategoryNodeImported(id){
            const element = document.querySelector(`.item-group[id='${id}']`);
            const categoryNode = element.closest('.content-list-wrap'); //find parent of element that contains class .content-list-wrap
            const categoryNodeId = categoryNode ? categoryNode.id : null; //get the id of that parent
            return this.getClientContentTree.nodes.find(node => node.id === categoryNodeId) ? this.getClientContentTree.nodes.find(node => node.id === categoryNodeId) : false;
            // const oldParentCategory = this.getImportedContentTree.nodes[0].nodesData.category ? this.getImportedContentTree.nodes[0].nodesData.category : false;
            // console.log('test-2', [oldParentCategory, element, categoryNodeId, newParentCategory]);
        },
        checkIfNodeMovedToImportedNodesList(id, parentID){
            console.log('step5a parent id', parentID)
            let doNoUpdate = false;
            const foundNode = this.getNodeObjectByIDFromStore(id);
            const newParentIsCategory = document.querySelector(`.content-list-wrap[id='${parentID}']`) ? true : false;
            const newParentEl = newParentIsCategory ? document.querySelector(`.content-list-wrap[id='${parentID}']`) : document.querySelector(`.item-group[id='${parentID}']`);
            const newParentIsImported = newParentIsCategory ? newParentEl.querySelector('.content-list').classList.contains('isImported') : newParentEl.closest('.isImported');
            console.log('======================', [newParentIsImported]);
            //1. Check if node came from main content tree and was placed into imported node list
            if(foundNode.node && !('isImported' in foundNode.node) && newParentIsImported){
                doNoUpdate = true;
                alert('You cannot place a node in the imported list');
                document.querySelector(`.item-group[id='${id}']`).classList.add('error');
            }
            //2 . Check if node is nested in the imported list.  (Only imported nodes are allowed to go back to an imported list)
            if(foundNode.node && foundNode.node.isImported && newParentIsImported && newParentIsCategory === false){
                doNoUpdate = true;
                alert('You cant nest within an imported list')
                document.querySelector(`.item-group[id='${id}']`).classList.add('error');
            }
            if(doNoUpdate === false){
                document.querySelector(`.item-group[id='${id}']`).classList.remove('error');
            }
            return doNoUpdate;
        },
        openCategoryNode(id, imported){
            if(!imported){
                this.$store.dispatch("client/selectNode", id);
                if (nodeOverview.classList.contains("overview-closed")) {
                    main.classList.add("overview-open")
                    main.classList.remove("overview-closed")
                    nodeOverview.classList.add("overview-open")
                    nodeOverview.classList.add("overview-closed")
			    }
            }else{
                alert('You can only re-order/move imported category nodes');
            }
        },
        closeNodeOverview() {
			if (nodeOverview.classList.contains("overview-open")) {
				main.classList.remove("overview-open")
				main.classList.add("overview-closed")
				nodeOverview.classList.remove("overview-open")
				nodeOverview.classList.remove("overview-closed")
			}
		},
		openNodeOverview() {
			if (nodeOverview.classList.contains("overview-closed") && this.getContentStrategy.isImporting === false) {
				main.classList.add("overview-open")
				main.classList.remove("overview-closed")
				nodeOverview.classList.add("overview-open")
				nodeOverview.classList.add("overview-closed")
			}
		},
        async ifNodeOpenOverview(){
            try{
                if (this.$route.query?.node) {
                    if (this.getClientContentTree.nodeIds.find((nodeId) => nodeId === this.$route.query?.node)) {
                        await this.$store.dispatch("client/selectNode", this.$route.query.node)
                        this.closeSidebar()
                        this.openNodeOverview()
                    }
                }
            }catch(error){
                console.log('openNodeOverview content list catch error', error);
            }
        },
        async nodesInit(){
            try{
                this.loading = true;
                console.log('nodesInit starting');
                const data = await this.$store.dispatch('client/mapContentTreeToList');
                this.categoryNodes = data[0].elements;
                if(this.getContentStrategy.isImporting){
                    this.addContentStrategyNodes();
                }else{
                    this.addImportedNodes();
                }
                console.log('nodesInit finished')
                await this.ifNodeOpenOverview();
                this.loading = false;
            }catch(error){
                console.log('nodesInit catch error', error);
            }
        },
        uuidv4() {
	        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16))
        },
        addContentStrategyNodes(){
            console.log('step1');
            let approvedNodes = JSON.parse(JSON.stringify(this.getContentStrategy.approvedNodes));
            approvedNodes.map(node => {
                console.log('step1a', node);
                node.isImported = true;
                node.value = node.fieldGroups.title.fields.title.value;
                node.accumulativeReach = 0;
                node.reach = 0;
                node.level = 2
            });
            console.log('step2', approvedNodes);
            if(approvedNodes.length > 0){
                console.log('step3');
                let mappedNodeData = new Node();
                mappedNodeData.id = this.uuidv4();
                mappedNodeData.isImported = true;
                mappedNodeData.level = 1;
                mappedNodeData.name = '1';
                mappedNodeData.title = 'Content Strategy';
                mappedNodeData.value = 'Content Strategy';
                mappedNodeData.category = 'self';
                mappedNodeData.elements = approvedNodes;
                console.log('step4', mappedNodeData);
                if(approvedNodes.length > 0){
                    if(this.categoryNodes.length > 0){
                        console.log('step5');
                        this.categoryNodes = [mappedNodeData, ...this.categoryNodes];
                        console.log('step6a', mappedNodeData);
                        console.log('step6b', this.categoryNodes);
                    }
                }
            }
        },
        addImportedNodes(){
            let platforms = {
                'facebook': false,
                'instagram': false,
                'linkedin': false,
                'wordpress': false
            }
            this.getImportedContentTree.nodes.forEach(obj => {
                const categoryId = obj.nodesData.category.id;
                const platform = obj.nodesData.category.value;
                const foundCategoryInContentList = this.categoryNodes.find(category => category.id === categoryId) ? true : false;
                if(foundCategoryInContentList){
                    platforms[platform] = true;
                }
            })
            const mappedNodeData = [];
            let importedContent = JSON.parse(JSON.stringify(this.getImportedContentTree.nodes));
            importedContent.forEach(obj => {
                console.log('importedContent', obj)
                if(platforms[obj.nodesData.category.value] === false){
                    let categoryNode = obj.nodesData.category;
                    categoryNode.isImported = true;
                    categoryNode.elements = obj.nodesData.nodes;
                    obj.nodesData.nodes.map(node => {
                        node.elements = [];
                    })
                    mappedNodeData.push(categoryNode);
                }
            })
            if(mappedNodeData.length > 0){
                console.log('MAPPED NODE DATA', mappedNodeData);
                if(this.categoryNodes.length > 0){
                    this.categoryNodes = [...mappedNodeData, ...this.categoryNodes];
                }
            }
        },
        async saveContentList(){
            try{
                const hasError = document.querySelectorAll(`.content-list-master .error`).length > 0 ? true : false;
                if(hasError){
                    alert('You have errors, look for the red highlighted list items');
                }else{
                    this.isSaving = true;
                    this.$store.commit('client/setHidePageLoader', false);
                    this.hideLoader = false;
                    setTimeout(async () => {
                        console.log('saveContentList starting changed nodes:', this.changedNodes);
                        this.changedNodes.forEach(id => {
                            const changedEl = document.querySelector(`.content-list-master .item-group[id='${id}']`);
                            const categoryIsParent = changedEl.querySelector('[level="2"]') ? true : false;
                            const categoryId = changedEl.closest('.content-list-wrap').id;
                            const parentID = categoryIsParent ? changedEl.closest('[level="1"]').id : changedEl.parentElement.parentElement.parentElement.parentElement.id;
                            const siblingBeforeID = changedEl.previousElementSibling ? changedEl.previousElementSibling.id : '';
                            const siblingNextID = changedEl.nextElementSibling ? changedEl.nextElementSibling.id : '';
                            //make sure to update the category, level 
                            const data = {
                                id: id, 
                                categoryID: categoryId, 
                                siblingBeforeID: siblingBeforeID, 
                                siblingNextID: siblingNextID, 
                                parentID: parentID, 
                                categoryIsParent: categoryIsParent
                            };
                            console.log('the data being sent:', data);
                            this.$store.commit('client/setChangeMovementOfNode', data);
                        })
                        const listWithoutImportedNodes = this.categoryNodes.filter(node => !node.isImported);
                        console.log('listWithoutImportedNodes', listWithoutImportedNodes);
                        const newOrderedNodes = this.updateName(JSON.parse(JSON.stringify(listWithoutImportedNodes)));
                        this.$store.commit('client/setUpdateIndexesOfNodes', newOrderedNodes);
                        //Move this onto server when done
                        await this.$store.dispatch("client/saveClientContentList");
                        alert('The Content list has been saved');
                        const data = await this.$store.dispatch('client/mapContentTreeToList');
                        this.categoryNodes = data[0].elements;
                        await this.$store.dispatch('client/getImportedContentTree');
                        this.$store.commit('client/setChangedNode', '');
                        this.$store.commit('client/clearSelectedNode');
                        this.userSaved = true;
                        this.hasMovedNode = true;
                        if(this.getContentStrategy.isImporting){
                            //go back to content strategy
                            this.$router.push({
                                name: 'Content Strategy',
                            });
                        }
                        location.reload();
                        this.hideLoader = true;
                        this.isSaving = false;
                    }, 2000);
                }

            }catch(error){
                console.log('saveContentList catch error', error);
            }
        },
        async addCategoryNodeToList(){

            try{
                //Set active node to be root. 
				this.$store.dispatch("client/addNewNode", {parentNodeId: this.getClientContentTree.rootNodeId, author: this.getAuthData.fullName});

                if (nodeOverview.classList.contains("overview-open")) {
					main.classList.remove("overview-open")
					main.classList.add("overview-closed")
					nodeOverview.classList.remove("overview-open")
					nodeOverview.classList.add("overview-closed")
				}

                main.classList.remove("node-form-closed")
                main.classList.add("node-form-open")
                nodeForm.classList.remove("form-closed")
                nodeForm.classList.add("form-open")

            }catch(error){
                console.log('addCategoryNodeToList catch error', error);
            }
        },
        getNodeObjectByIDFromStore(id){
            let changedNode;
            let changedNodeIndex;
            if(this.getClientContentTree.nodes.find(node => node.id === id)){
                changedNode = this.getClientContentTree.nodes.find(node => node.id === id);
                changedNodeIndex = this.getClientContentTree.nodes.findIndex(node => node.id === id);
            }else if(this.getContentStrategy.isImporting){
                changedNode = this.getContentStrategy.nodes.find(node => node.id === id);
                changedNodeIndex = this.getContentStrategy.nodes.findIndex(node => node.id === id);
            }else{
                this.getImportedContentTree.nodes.forEach(categoryNode => {
                    categoryNode.nodesData.nodes.forEach((node, index) => {
                        if(node.id === id){
                            changedNode = node;
                            changedNodeIndex = index;
                        }
                    });
                });
            }
            console.log('step6a',{node: changedNode, index: changedNodeIndex})
            return {node: changedNode, index: changedNodeIndex};
        },
        handleEnd(event){
            const id = event.item.getAttribute('id');
            this.$store.commit('client/setChangedNode', id);
        },
        removeFoundNodeFromCurrentLocation(nodes, id){
            return nodes.reduce((acc, node) => {
            // If the node id matches the data id, don't include it in the new array
            if (node.id === id) {
                return acc;
            }
            // If the node has elements, recursively filter them
            if (node.elements) {
                node.elements = this.removeFoundNodeFromCurrentLocation(node.elements, id);
            }
            // If the node id doesn't match the data id, include it in the new array
            acc.push(node);
            return acc;
            }, []);
        },
        async moveChangedNodeInLocalData(data){
            //This function only changes (this.categoryNodes which is this components local store/data/state)
            //if doNotUpdate = false = We have the new location from the dom going to add the changes  to the local store.
            //if doNotUpdate = true = We have the old location from the vuex store going to revert back to the old location to local store.
            // id: response.changedNode.id, 
            // siblingBeforeID: response.prevSibling.id, 
            // siblingNextID: response.nextSibling.id, 
            // parentID: response.parentNode.id,
            // categoryIsParent: data.categoryIsParent,
            // doNotUpdate: data.doNotUpdate

            //Have to get the foundNode this way so it creates a copy and not a ref.
            const foundNode = {...(this.categoryNodes.find(node => node.id === data.id) || 
                            this.categoryNodes.flatMap(node => node.elements).find(element => element.id === data.id))};
            if (foundNode) {
                // Remove the found node from its current location
                this.categoryNodes = this.removeFoundNodeFromCurrentLocation(this.categoryNodes, data.id);
                // Find the new parent node
                
                const parentNode = this.categoryNodes.find(node => node.id === data.parentID) || 
                                this.categoryNodes.flatMap(node => node.elements).find(element => element.id === data.parentID);

                if (parentNode) {
                    // If siblingBeforeID is provided, insert the found node after it
                    if (data.siblingBeforeID) {
                        let index = parentNode.elements.findIndex(element => element.id === data.siblingBeforeID);
                        if (index !== -1) {
                            parentNode.elements.splice(index + 1, 0, foundNode);
                        }
                    }
                    // If siblingNextID is provided, insert the found node before it
                    else if (data.siblingNextID) {
                        let index = parentNode.elements.findIndex(element => element.id === data.siblingNextID);
                        if (index !== -1) {
                            parentNode.elements.splice(index, 0, foundNode);
                        }
                    }
                    // If neither is provided, add the found node to the end
                    else {
                        parentNode.elements.push(foundNode);
                    }
                    // this.moveChangedNodeInDOM(data);
                } else {
                    console.log('Parent node not found');
                }
            } else {
                console.log('Node not found');
            }
        },
        getChangedNodeObjectFromStore(id){
            //This gets the node from the VUEX store (which would not have been updated so would be old location)
            //WE use this to revert the changes if doNotUpdate = true
            let changedNode = false;
            let changedNodeIndex = false;
            let parentNodeId = false;
            let parentNode = false;
            let prevSibling = false;
            let nextSibling = false;
            let categoryNode = false;
            const response = this.getNodeObjectByIDFromStore(id);
            changedNode = response.node;
            changedNodeIndex = response.index;
            parentNodeId = changedNode.parent;
            if(this.getClientContentTree.nodes.find(node => node.id === parentNodeId)){
                parentNode = this.getClientContentTree.nodes.find(node => node.id === parentNodeId);
            }else{
                parentNode = this.getImportedContentTree.nodes.find(categoryNode => categoryNode.nodesData.category.id === parentNodeId);
                parentNode = parentNode.nodesData.category;
            }
            if(changedNode.isImported){
                categoryNode = this.getImportedContentTree.nodes.find(categoryNode => categoryNode.nodesData.category.id === changedNode.category);
                categoryNode.nodesData.nodes.forEach((node, index) => {
                    if(index === (changedNodeIndex - 1)){
                        prevSibling = node;
                    }
                    if(index === (changedNodeIndex + 1)){
                        nextSibling = node;
                    }
                })
            }else{
                categoryNode = this.getClientContentTree.nodes.find(node => node.id === changedNode.category);
                prevSibling = this.getClientContentTree.nodes.find((node, index) => index === (changedNodeIndex - 1));
                nextSibling = this.getClientContentTree.nodes.find((node, index) => index === (changedNodeIndex + 1));
            }
            const data = {
                changedNode: changedNode,
                parentNode: parentNode,
                prevSibling: prevSibling,
                nextSibling: nextSibling
            };
            return data;
        },
        async setChangeMovementOfNode(data){
            try{
                if(data.doNotUpdate === false){
                    //We have all the data from the DOM of the new localtion,
                    // its now time to add this to the local data store. 
                    console.log('setChangeMovementOfNode update');
                    this.moveChangedNodeInLocalData(data);
                    this.changedNodes.push(data.id);
                }else{
                    //We have to get the orginal parent where the changed node came 
                    //from and update the local state to revert the moevement/changes.
                    const response = this.getChangedNodeObjectFromStore(data.id);
                    console.log('setChangeMovementOfNode do not update', response);
                    const data2 = {
                        id: response.changedNode.id, 
                        siblingBeforeID: response.prevSibling.id, 
                        siblingNextID: response.nextSibling.id, 
                        parentID: response.parentNode.id,
                        categoryIsParent: data.categoryIsParent,
                        doNotUpdate: data.doNotUpdate
                    };
                    this.moveChangedNodeInLocalData(data2);                
                }
            }catch(error){
                console.log('setChangeMovementOfNode catch error', error);
            }
        },
        async checkIfSibling(siblingBeforeID, siblingNextID){
            try{
                let doNotUpdate = false;
                if(siblingBeforeID && siblingNextID){
                    if(document.querySelector(`.item-group[id='${siblingBeforeID}']`).classList.contains('no-drag') && document.querySelector(`.item-group[id='${siblingNextID}']`).classList.contains('no-drag')){
                        doNotUpdate = true;
                        alert('It is not possible to insert a node between two interconnected social nodes');
                        // const newArr = await this.$store.dispatch('client/mapContentTreeToList');
                        // this.categoryNodes = newArr;
                        this.$store.commit('client/setChangedNode', '');
                        this.$store.commit('client/clearSelectedNode');
                    }
                }
                return doNotUpdate;
            }catch(error){
                console.log('checkIfSibling catch error', error);
            }
        },
        flattenArray(arr) {
            let result = [];
            arr.forEach(item => {
                result.push(item);
                if (item.elements && item.elements.length > 0) {
                    result = result.concat(this.flattenArray(item.elements));
                }
            });
            return result;
        },
        updateName(arr, prefix = '') {
            arr.forEach((item, index) => {
                item.name = prefix + (index + 1);
                let level = parseInt(item.name.split('.')[0]);
                item.level = isNaN(level) ? item.level : level;
                const el = document.querySelector(`.content-list-master .item[id='${item.id}']`);
                if(el){
                    let innerHTMLArray = el.innerHTML.split(' - ');
                    innerHTMLArray[0] = item.name;
                    el.innerHTML = innerHTMLArray.join(' - ');
                    el.setAttribute('level', item.level);   
                }
                if (item.elements && item.elements.length > 0) {
                    this.updateName(item.elements, item.name + '.');
                }
            });
            const newArray = this.flattenArray(arr);
            return newArray;
        }

    },
    computed:{
        dragOptions() {
            return {
                animation: 200,
                group: "description",
                disabled: false,
                ghostClass: "ghost",
                filter: ".no-drag",
                preventOnFilter: true,
                handle: '.hover-icon2'
            };
        },
        ...mapGetters("auth", ["getAuthData"]),
        ...mapGetters("views", ["getSidebarOpenState"]),
		...mapGetters("client", ["getLockContentTree", "getContentStrategy", "getMutliSelectNodeCategories", "getClientContentTree", "getChangedNode", "getContentTreeFilters", "getCurrentNode", "getClientData", "getHighlightedNode", "getListView", "getClientSlug", "getContentTreeSiblingIds", "getNodesSiblingData", "getLockListCategories", 'getImportedContentTree']),
    },
}
</script>


<style lang="scss">
.content-list-master > .dragArea{
    display: flex;
    padding-left: 60px;
    overflow-x: scroll;
    padding-top: 20px;
    padding-block: 20px;
    /* width */
    &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: $darkblue;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $lavender;
    }

    width: 88vw !important;
    height: 69vh !important;
    // border: 10px solid #131129 !important;
    border-radius: 7px;
}

.main-content.content-list-open .container{
    overflow: hidden;
	display: flex;
	justify-content: center;
    padding-bottom: 100px !important;
    max-width: unset !important;
}
.content-list-open{
    height: 100vh;
}
.content-list__commands{
    z-index: 1;
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    button{
        background: #131129;
        margin-right: 10px;
        max-width: unset;
        &:nth-last-of-type(){
            margin-right: 0;
        }
    }
    button.btn-save:not(.btn-save--outline){
        background: $btn-save;
    }
    .content-list-lock-icon{
        width: 117px !important;
    }
    .btn-save{
        width: 177px !important;
    }
    .btn-list-view{
        min-width: 145px !important;
        width: 145px !important;
    }
}
.content-list{
    width: 300px;
    padding: 20px;
    transition: 0.2s ease;
    background: #3D4163;
    border-radius: 20px;
    cursor: pointer;
    border: 1px solid transparent;
    transition: 0.2s ease all;
    &:hover{
        border: 1px solid $btn-primary;
        width: 350px;
    }
    margin-right: 30px;
    &.isImported{
        background: #1311296f;
    }
}
.content-list__add-category-btn{
    cursor: pointer;
    margin-right: 20px;
}
.content-list-root-node{
    display: flex;
    justify-content: center;
}

.content-list-wrap{
    height: 100%;
    &:last-of-type{
        margin-right: 200px;
    }
}




.item-group .item-wrap{
    display: flex;
    align-content: center;
    transition: 0.2s ease all;
}
.item-group .hover-icon{
    display: none;
    color: white;
    font-size: 25px;
    margin-right: 10px;
    transition: 0.2s ease all;
}
.content-list-unlocked .item-group:not(.no-drag):not(.ghost) .item-wrap:hover{
    .hover-icon{
        display: flex;
        align-items: center;
    }
}

.content-list-master{
	transition: 0.2s all ease !important;
}
.content-list-master.sidebar-open-padding-left{
	// padding-left: 300px !important;
	> .dragArea{
		width: 74vw !important;
	}
}

.hover-icon2{
    cursor: move;
    position: relative;
}

.content-list-wrap > div{
        /* width */
        &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    /* Track */
    &::-webkit-scrollbar-track {
        background: $darkblue;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $lavender;
    }
}
</style>


<style lang="scss">
  
.item-group.error .item{
border: 1px solid red !important;
}

</style>
